import {ITestsRepository} from './ITestsRepository';
import {tests, test} from './TestsQueries';
import {createTest} from './TestMutations';
import {TestUpdate} from '../../graphql/API';
import {updateTest} from '../../graphql/mutations';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
const client = generateClient();
export class TestsRepository implements ITestsRepository {
  async getTests(): Promise<any> {
    try {
      let allTests: any[] = [];
      let nextToken: string | null = null;

      do {
        const response: GraphQLResult<any> = await client.graphql({
          query: tests,
          variables: { nextToken: nextToken },
        });

        if (response.data?.tests?.items) {
          allTests = [...allTests, ...response.data.tests.items];
        }

        nextToken = response.data.tests.nextToken;
      } while (nextToken);

      return allTests;
    } catch (error) {
      console.log('getTests repository Error:', error);
    }
  }

  async getTestById(testId: string): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: test,
        variables: {
          id: testId,
        },
      });

      return response.data?.test;
    } catch (error) {
      console.log('Fetch test Error: ', error);
    }
  }

  async createTest(test: any): Promise<any> {
    try {
      const createTestResponse: GraphQLResult<any> = await client.graphql({
        query: createTest,
        variables: {
          input: test,
        },
      });

      return createTestResponse.data?.createTest;
    } catch (error) {
      console.log('Create Test Error: ', error);
    }
  }
  async updateTest(
    id: string,
    test: TestUpdate,
    updatedAt: string,
  ): Promise<any> {
    try {
      const updateTestResponse: GraphQLResult<any> = await client.graphql({
        query: updateTest,
        variables: {
          updatedAt,
          id,
          input: test,
        },
      });

      return updateTestResponse.data?.updateTest;
    } catch (error) {
      console.log('Create Test Error: ', error);
    }
  }
}
